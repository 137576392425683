import {FC, useContext} from "react";
import {GoogleLogin} from "@react-oauth/google";
import TokenContext from "../TokenContext";

const LoginView: FC = () => {
    const authContext = useContext(TokenContext);

    return (
        <div style={{display: "flex", alignItems: "center", justifyContent: "center", width: "100vw", height: "100vh"}}>
            <GoogleLogin auto_select hosted_domain="neurosys.com" size="large" shape="pill" onSuccess={credentialResponse => {
                authContext.setToken(credentialResponse.credential);
            }}/>
        </div>
    )
};

export { LoginView };

