import {Await, useLoaderData} from "react-router";
import Grid2 from "@mui/material/Unstable_Grid2";
import {
    Card,
    CardContent,
    Divider,
    List,
    ListItem,
    ListItemAvatar,
    ListItemButton,
    ListItemText,
    Skeleton
} from "@mui/material";
import {AxiosResponse} from "axios";
import {FC, Fragment, Suspense} from "react";
import {Link} from "react-router-dom";
import Avatar from "../components/Avatar";

const SkeletonListItem = () => (
    <ListItem>
        <ListItemAvatar><Skeleton variant="circular" width="2em" height="2em"/></ListItemAvatar>
        <ListItemText>
            <Skeleton variant="text" width="20em"/>
        </ListItemText>
    </ListItem>
);

const FallbackList = () => (
    <Fragment key="skeleton">
        <SkeletonListItem key="0"/>
        <Divider variant="inset" component="li" key="1"/>
        <SkeletonListItem key="2"/>
        <Divider variant="inset" component="li" key="3"/>
        <SkeletonListItem key="4"/>
    </Fragment>
);

interface EmailListItemProps {
    email: string,
}

const EmailListItem: FC<EmailListItemProps> = ({email}) => (
    <ListItemButton component={Link} to={"/" + email}>
        <ListItemAvatar>
            <Avatar email={email} />
        </ListItemAvatar>
        <ListItemText>{email}</ListItemText>
    </ListItemButton>
)

const EmailsList = (response: AxiosResponse<string[]>) => (
    <>
        {response.data.sort().map((email, index) => (
            <Fragment key={"entry-" + index}>
                { index > 0 ? <Divider variant="inset" component="li" key={"divider-" + index} /> : null }
                <EmailListItem key={email} email={email} />
            </Fragment>
        ))}
    </>
);

const EmailListView = () => {
    const loaderData = useLoaderData() as {emails: Promise<AxiosResponse<string[]>>};

    return (
        <Grid2 display="flex" justifyContent="center" alignItems="center" style={{minHeight: "100vh"}}>
            <Card>
                <CardContent>
                    <List>
                        <Suspense fallback={<FallbackList />}>
                            <Await resolve={loaderData.emails}>
                                {EmailsList}
                            </Await>
                        </Suspense>
                    </List>
                </CardContent>
            </Card>
        </Grid2>
    )
};

export default EmailListView;
