import {useRouteError} from "react-router";
import Grid2 from "@mui/material/Unstable_Grid2";
import {Alert, AlertTitle, Box, Button, Typography} from "@mui/material";
import {Link} from "react-router-dom";

interface RouteErrorType {
    statusText?: string,
    message?: string,
}

const ErrorPage = () => {
    const error = useRouteError() as RouteErrorType;
    console.error(error);

    return <Grid2 display="flex" justifyContent="center" alignItems="center" style={{height: "100vh"}}>
        <Box>
            <Alert variant="outlined" severity="error" elevation={8}>
                <AlertTitle>Error</AlertTitle>
                { error.statusText || error.message }
            </Alert>
            <Typography textAlign="center">
                <Button style={{marginTop: "1em"}} color="error" component={Link} to="/">Back to app</Button>
            </Typography>
        </Box>
    </Grid2>
}

export default ErrorPage;
