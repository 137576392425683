import {FC, Suspense, useState} from "react";
import {
    Avatar as MUIAvatar,
    Card,
    CardContent,
    CardHeader,
    Dialog,
    IconButton,
    Skeleton,
    Typography
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import {Await, useLoaderData, useNavigate, useNavigation, useParams} from "react-router";
import {AxiosResponse} from "axios";
import {BudgetApiResponse} from "../models/BudgetApiResponse";
import Avatar from "../components/Avatar";
import MonetaryDisplay from "../components/MonetaryDisplay";
import {CalendarPicker} from "@mui/x-date-pickers";
import {useSearchParams} from "react-router-dom";
import {DateTime} from "luxon";
import getValidDateQueryString from "../helpers/getValidDateQueryString";
import EventIcon from '@mui/icons-material/Event';

const AvatarSkeleton = () => <MUIAvatar variant="circular" />;
const SubheaderSkeleton = () => <Skeleton variant="rectangular" width="10rem"/>;
const DisplaySkeleton = () => <Skeleton variant="rectangular" height="3rem"/>;
const TitleSkeleton = () => <>Upskill budget</>;

const CardBudgetSkeleton = () => (
    <Card style={{maxWidth: "30vw", width: "300px"}} elevation={8}>
        <CardHeader
            avatar={<AvatarSkeleton/>}
            title={<TitleSkeleton />}
            subheader={<SubheaderSkeleton />}
            action={<IconButton disabled><EventIcon /></IconButton>}
        />
        <CardContent>
            <DisplaySkeleton />
        </CardContent>
    </Card>
)

const CardBudgetDisplay = (response: AxiosResponse<BudgetApiResponse>) => {
    const navigation = useNavigation();
    const isBusy = navigation.state !== "idle";
    const [isPickerOpen, setPickerOpen] = useState(false);
    const [searchParams] = useSearchParams();
    const currentDateParam = DateTime.fromISO(getValidDateQueryString(searchParams));
    const email = useParams().email || '';
    const navigate = useNavigate();

    const avatar = isBusy ? <AvatarSkeleton /> : <Avatar name={response.data.name} picture={response.data.picture} email={email}/>;
    const subheader = isBusy
        ? <SubheaderSkeleton /> :
        <>
            Budget for day {currentDateParam.setLocale('en-GB').toLocaleString({dateStyle: "medium"})}
        </>;

    const display = isBusy ? <DisplaySkeleton /> : (
        <Typography variant="h3" align="center" component="div">
            <MonetaryDisplay currency="PLN" locale="pl-PL" value={response.data.budget/100.0} />
        </Typography>
    );

    return <Card style={{maxWidth: "30vw"}} elevation={8}>
        <CardHeader
            avatar={avatar}
            title={<TitleSkeleton />}
            subheader={subheader}
            action={
                <IconButton disabled={isBusy} onClick={() => setPickerOpen(true)}>
                    <EventIcon/>
                </IconButton>
            }
        />
        <CardContent>
            <Dialog
                open={isPickerOpen}
                onClose={() => setPickerOpen(false)}
            >
                <CalendarPicker
                    onChange={(newValue: DateTime | null) => {
                        if (newValue !== null) {
                            navigate(
                                '/' + email + "?date=" + newValue.toISODate(),
                                {
                                    replace: true,
                                    state: newValue
                                }
                            );
                            setPickerOpen(false);
                        }
                    }}
                    minDate={DateTime.fromISO((DateTime.now().year - 1) + '01-01')}
                    date={currentDateParam}
                />
            </Dialog>
            {display}
        </CardContent>
    </Card>
}

const UpskillBudgetView: FC = () => {
    const loaderData = useLoaderData() as {data: Promise<AxiosResponse>};

    return <Grid2 display="flex" justifyContent="center" alignItems="center" style={{minHeight: "100vh"}}>
            <Suspense fallback={<CardBudgetSkeleton/>}>
                <Await resolve={loaderData.data}>
                    {CardBudgetDisplay}
                </Await>
            </Suspense>
    </Grid2>;
};

export default UpskillBudgetView;
