import {Router} from "@remix-run/router";
import {FC} from "react";
import {LoginView} from "../views/LoginView";
import {RouterProvider} from "react-router-dom";

interface ForcedAuthRouterProps {
    isAuthenticated: boolean;
    router: Router
}

const ForcedAuthRouter: FC<ForcedAuthRouterProps> = ({isAuthenticated, router}) => {
    if (!isAuthenticated) {
        return <LoginView />;
    }

    return <RouterProvider router={router}/>;
}

export default ForcedAuthRouter;
