import axios, {AxiosError} from "axios";
import {ApiErrorResponse} from "./models/ApiErrorResponse";

const instance = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
});
instance.interceptors.response.use(
    response => response,
    (error: AxiosError) => {
        if (error.response?.status === 401) {
            sessionStorage.removeItem('token');
            window.location.replace("/");
        }
        const responseData = error.response?.data as ApiErrorResponse ;
        if (responseData && responseData.message) {
            throw new AxiosError(responseData.message, error.code, error.config, error.request, error.response);
        }

        throw error;
    }
)

export default instance;
