import {useState} from "react";
import jwtDecode from "jwt-decode";
import axios from "../axios";

interface GoogleJWTInterface {
    name?: string,
    picture?: string,
    email?: string,
}

const useApplicationState = () => {
    const [token, setTokenState] = useState<string>();
    const [picture, setPicture] = useState<string>();
    const [name, setName] = useState<string>();
    const [email, setEmail] = useState<string>();

    const isAuthenticated = () => token !== undefined && token.length > 0;

    const setToken = (newToken?: string) => {
        if (newToken === undefined) {
            setToken(undefined);
            setPicture(undefined);
            setName(undefined);
            setEmail(undefined);
            return;
        }
        const decoded = jwtDecode<GoogleJWTInterface>(newToken);
        setTokenState(newToken);
        if (decoded.picture) {
            setPicture(decoded.picture);
        }
        if (decoded.name) {
            setName(decoded.name);
        }
        if (decoded.email) {
            setEmail(decoded.email);
        }
        sessionStorage.setItem('token', newToken);
        axios.defaults.headers.get['Authorization'] = 'Bearer ' + newToken;
    }

    const sessionToken = sessionStorage.getItem('token');
    if (!token && sessionToken) {
        setToken(sessionToken);
    }

    return {
        token,
        setToken,
        picture,
        name,
        email,
        isAuthenticated,
    };
}

export default useApplicationState;
