import {DateTime} from "luxon";

const getValidDateQueryString = (searchParams: URLSearchParams) => {
    let date = DateTime.now().toISODate();

    try {
        const dateParam = searchParams.get('date');
        if (dateParam) {
            const dateInstance = DateTime.fromISO(dateParam);
            return dateInstance.toISODate();
        }
    } catch (err) {
        console.warn(err);
    }

    return date;
}

export default getValidDateQueryString;
