import {FC} from "react"
import {Avatar as MUIAvatar} from "@mui/material";

function stringToColor(string: string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

function avatarChildren(name: string) {
    try {
        return `${name.split(' ')[0][0].toUpperCase()}${name.split('.')[1][0].toUpperCase()}`;
    } catch (err) {
        return name.substring(0, 1).toUpperCase();
    }
}

function stringAvatar(name: string) {
    return {
        sx: {
            bgcolor: stringToColor(name),
        },
        children: avatarChildren(name),
    };
}

interface AvatarProps {
    name?: string,
    picture?: string,
    email: string,
}
const Avatar: FC<AvatarProps> = ({email, name, picture}) =>
    <MUIAvatar src={picture} alt={name} variant="circular" {...stringAvatar(email)} />

export default Avatar;
