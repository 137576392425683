import {FC} from "react";

interface MonetaryDisplayProps {
    currency: string,
    locale: string,
    value: number,
}

const MonetaryDisplay: FC<MonetaryDisplayProps> = ({currency, locale, value}) => {
    try {
        const formatter = Intl.NumberFormat(locale, {
            style: "currency",
            currency: currency,
        });

        return <>{formatter.format(value)}</>;
    } catch (err) {
        console.error(err);
        return <>{value}</>;
    }
}

export default MonetaryDisplay;
