import './App.css';
import {useMemo} from "react";
import TokenContext from "./TokenContext";
import useApplicationState from "./hooks/useApplicationState";
import {createTheme, ThemeProvider, useMediaQuery} from "@mui/material";
import {createBrowserRouter} from "react-router-dom";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterLuxon} from "@mui/x-date-pickers/AdapterLuxon";
import generateRoutes from "./routes";
import ForcedAuthRouter from "./components/ForcedAuthRouter";

function App() {
    const appState = useApplicationState();
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const theme = useMemo(
        () =>
            createTheme({
                palette: {
                    mode: prefersDarkMode ? 'dark' : 'light',
                },
            }),
        [prefersDarkMode],
    );

    const routes = generateRoutes(appState.email || '');
     const router = createBrowserRouter(routes);

    return (
        <LocalizationProvider dateAdapter={AdapterLuxon}>
            <ThemeProvider theme={theme}>
                <TokenContext.Provider value={appState}>
                    <ForcedAuthRouter isAuthenticated={appState.isAuthenticated()} router={router}/>
                </TokenContext.Provider>
            </ThemeProvider>
        </LocalizationProvider>
    )
}

export default App;
