import {defer, Navigate, RouteObject} from "react-router-dom";
import ErrorPage from "./views/errors/ErrorPage";
import EmailList from "./views/EmailList";
import axios from "./axios";
import UpskillBudgetView from "./views/UpskillBudgetView";
import getValidDateQueryString from "./helpers/getValidDateQueryString";
import {BudgetApiResponse} from "./models/BudgetApiResponse";

const generateRoutes = (defaultEmail: string): RouteObject[] => [
    {
        index: true,
        path: '/',
        element: <Navigate to={"/" + defaultEmail}/>,
        errorElement: <ErrorPage/>
    },
    {
        path: '/emails',
        element: <EmailList/>,
        errorElement: <ErrorPage/>,
        loader: ({request}) => {
            return defer({emails: axios.get<string[]>("/emails", {
                signal: request.signal,
            })});
        }
    },
    {
        path: '/:email',
        element: <UpskillBudgetView/>,
        errorElement: <ErrorPage/>,
        loader: ({params, request}) => {
            const url = new URL(request.url);
            const dateParam = getValidDateQueryString(url.searchParams);
            return defer({data: axios.get<BudgetApiResponse>("/budget/" + params.email, {
                signal: request.signal,
                params: {
                    date: dateParam
                }
            })})
        }
    }
];

export default generateRoutes;
