import {createContext} from "react";

interface TokenContextProps {
    name?: string,
    picture?: string,
    email?: string,
    token?: string,
    setToken: (token?:string) => void,
}

const defaultSetter = (_val?: string) => {
    throw new Error("You need to configure setters on TokenContext");
}

export default createContext<TokenContextProps>({
    setToken: defaultSetter
});


